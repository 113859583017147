<template>
  <div>
    <v-dialog
      v-model="isActive"
      persistent
      transition="dialog-transition"
      width="500"
      height="180"
    >
      <span class="d-none">{{ change }}</span>

      <v-card class="rounded-lg">
        <v-card-title>
          <v-row>
            <v-col cols="11" class="px-0">
              <h6 class="text-subtitle-1 font-weight-bold text-center">
                <span class="font-work-sans ml-5"
                  >{{ edit ? 'Modificar ' : 'Crear ' }}Bioanalista</span
                >
              </h6>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-btn
                color="primary"
                icon
                @click="
                  () => {
                    bioanalystData.signature = null
                    $refs.form.reset()
                    $emit('dismiss')
                  }
                "
                ><v-icon small>fa-times</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <label class="ml-4 black--text font-weight-medium">Id</label>
                <v-text-field
                  placeholder="Id"
                  color="primary"
                  rounded
                  prepend-inner-icon="fa-file-alt"
                  :rules="[rules.required, rules.integer]"
                  required
                  outlined
                  class="mt-2 mb-n3"
                  v-model="bioanalystData.id"
                  :disabled="edit"
                ></v-text-field>

                <label class="ml-4 black--text font-weight-medium"
                  >Nombre</label
                >
                <v-text-field
                  placeholder="Nombre"
                  color="primary"
                  rounded
                  prepend-inner-icon="fa-file-alt"
                  :rules="[rules.required, rules.alfanumeric]"
                  required
                  outlined
                  class="mt-2 mb-n3"
                  v-model="bioanalystData.name"
                ></v-text-field>

                <label class="ml-4 black--text font-weight-medium"
                  >Nro. Colegio</label
                >
                <v-text-field
                  placeholder="Nro. Colegio"
                  color="primary"
                  rounded
                  :rules="[rules.required, rules.alfanumeric]"
                  prepend-inner-icon="fa-file-alt"
                  required
                  outlined
                  class="mt-2 mb-n3 font-quicksand"
                  v-model="bioanalystData.collegeNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label class="ml-4 black--text font-weight-medium">Firma</label>
                <v-file-input
                  :rules="[requiredSignature]"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Firma"
                  color="primary"
                  prepend-icon=""
                  prepend-inner-icon="mdi-camera"
                  required
                  outlined
                  rounded
                  class="mt-2 mb-n3 font-quicksand"
                  v-model="bioanalystData.firma"
                  @change="setSignature"
                ></v-file-input>

                <v-img :src="bioanalystData.signature"></v-img>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-n4">
          <v-btn
            color="primary"
            outlined
            class="rounded-lg px-4"
            @click="
              () => {
                bioanalystData.signature = null
                $refs.form.reset()
                $emit('dismiss')
              }
            "
          >
            <v-icon small>fa-times</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            class="rounded-lg elevation-0 px-4"
            @click="submit"
            :loading="loading"
          >
            <v-icon small>fa-check</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-edit
      :item="item"
      :dialog="dialogEdit"
      @dismiss="
        () => {
          dialogEdit = false
        }
      "
      @accept="activeAlerts()"
    />
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
import Swal from 'sweetalert2'
import DialogEdit from '@/components/DialogEdit.vue'
const BioanalystRepository = Repository.get('bioanalysts')

export default {
  components: { DialogEdit },
  name: 'FormDialogBioanalyst',
  props: {
    bioanalyst: {
      type: Object,
      default: { id: '', name: '', collegeNumber: '', signature: null },
    },
    dialog: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
    valid: false,
    loading: false,
    bioanalystData: {
      id: '',
      name: '',
      collegeNumber: '',
      signature: '',
      firma: null,
    },
    dialogEdit: false,
    item: {},
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      alfanumeric: (value) => {
        const pattern = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
        return !pattern.test(value) || 'Campo inválido.'
      },
      integer: (value) => {
        const pattern = /[^0-9]/
        return !pattern.test(value) || 'Campo inválido.'
      },
    },
  }),
  computed: {
    change: async function () {
      try {
        this.isActive = await this.dialog
        if (this.bioanalyst)
          this.bioanalystData = Object.assign({}, this.bioanalyst)
      } catch (error) {
        console.log(error)
      }
    },
  },
  methods: {
    submit: function () {
      this.$refs.form.validate()

      setTimeout(() => {
        if (this.valid) {
          if (!this.edit) this.activeAlerts()
          else {
            this.item = {
              type: 'bioanalista',
              id: this.bioanalyst.id,
              name: this.bioanalyst.name,
            }
            this.dialogEdit = true
          }
        }
      })
    },

    activeAlerts: async function () {
      this.dialogEdit = false

      const data = {
        type: 'success',
        title: 'Petición exitosa',
        text: this.edit
          ? 'Bioanalista actualizado con éxito'
          : 'Bioanalista registrado con éxito',
      }

      this.loading = true

      try {
        const value = new FormData()
        value.append('id', this.bioanalystData.id)
        value.append('name', this.bioanalystData.name)
        value.append('collegeNumber', this.bioanalystData.collegeNumber)

        if (this.bioanalystData.firma)
          value.append('signature', this.bioanalystData.firma)
        else value.append('signature', this.bioanalystData.signature)

        this.edit
          ? await BioanalystRepository.update(value, this.bioanalystData.id)
          : await BioanalystRepository.create(value)

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$refs.form.reset()
        this.bioanalystData.signature = null
        this.$emit('dismiss')
      } catch (error) {
        const response = error.response
          ? error.response.data.data ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },

    setSignature: function () {
      if (this.bioanalystData.firma)
        this.bioanalystData.signature = URL.createObjectURL(
          this.bioanalystData.firma
        )
    },

    requiredSignature: function () {
      return !!this.bioanalystData.signature || 'Campo requerido.'
    },
  },
}
</script>
