<template>
  <div>
    <bioanalysts />
  </div>
</template>

<script>
import Bioanalysts from '@/components/catalog/Bioanalysts/Bioanalysts.vue'

export default {
  components: { Bioanalysts },
  name: 'Bioanalyst',
}
</script>
